import HttpService from '../HttpService'

export default class VaccineService {
    constructor() {
        this._httpService = new HttpService(`healthcare/vaccine`);
    }

    async getVaccineAllSpecialities(){
        return await this._httpService.get('/');
    }

}
