<template>
  <v-container
    :style="{
      height: '100%',
      display: 'block',
      width: '100vw',
      background: !register ? 'rgba(255, 255, 255, .5)' : 'white',
    }"
    class="pa-6 pt-2 fluid bg-effect"
  >
    <backArrow v-if="!register" :route="'root'" />
    <v-layout
      class="mt-1"
      v-if="!register && !registerSuccess"
      align-end
      justify-end
    >
      <img
        @click="$router.push('/principais-duvidas')"
        src="/img/nick/question-nick.svg"
        style="width: 55px"
      />
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-start justify-start>
      <v-row>
        <v-col cols="6" class="pb-0"> </v-col>
      </v-row>
    </v-layout>
    <Totalizer
      v-if="!register && !registerSuccess"
      class="mb-2 pt-2"
      :totals="totals"
      title="Vacinas"
      title-icon="/img/vaccine/vaccine-image.svg"
      color="#2EC9D3"
      :height="80"
      :tile-icon-right="40"
      :tile-icon-top="35"
      :tile-icon-width="130"
      :tile-icon-height="110"
    />
    <v-row class="pt-0 mt-0 pb-1 mb-1 px-4">
      <v-col cols="12" class="pt-0 mt-0 pb-1 mb-1">
        <v-autocomplete
          v-if="showComboBeneficiaries"
          label="Selecione o beneficiário"
          :items="beneficiaries"
          v-model="user"
          return-object
          item-value="cpf"
          item-text="name"
          color="primary"
          :disabled="busy"
          @change="onClickComboBeneficiary()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-layout v-if="!register && !registerSuccess" justify-center align-center>
      <v-card
        style="background: rgba(231, 234, 254, 0.6)"
        class="option-list-card list-card mb-6"
      >
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
              :class="!activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center filter-text generic-text mt-1 mb-0"
                @click="onClickTab()"
              >
                Próximas e Atrasadas
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5" cols="5">
            <div
              :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="
                  text-center
                  history-margin-adjust
                  filter-text
                  generic-text
                  mb-0
                "
                @click="onClickTab()"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-center justify-center>
      <ProgressiveLoader
        class="container"
        :disabled="busy"
        @reachedEnd="getVaccines"
      >
        <v-row v-if="!activeFilter" align="center" justify="center">
          <v-col v-if="lateVaccines.length > 0" cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Atrasadas
            </p>
          </v-col>
          <v-col v-for="vaccine in lateVaccines" :key="vaccine.id" cols="12">
            <VaccineCard
              v-if="isWaiting(vaccine)"
              color="tertiary"
              :detail-vaccine="vaccine"
              :action="
                () => {
                  currentVaccine = vaccine;
                  realizeVaccine = false;
                  register = true;
                  setStatusToShowCombo(false);
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = true;
                }
              "
            />
            <VaccineCard
              v-else-if="isScheduled(vaccine)"
              color="green"
              :detail-vaccine="vaccine"
              btn-title="Comparecimento"
              scheduled-type
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentVaccine = vaccine;
                  realizeVaccine = true;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = true;
                }
              "
            />
          </v-col>
          <v-col
            cols="12"
            v-if="nextVaccines.length > 0 || waitingVaccines.length > 0"
          >
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Próximas
            </p>
          </v-col>
          <v-col v-for="vaccine in nextVaccines" :key="vaccine.id" cols="12">
            <VaccineCard
              v-if="isScheduled(vaccine)"
              color="green"
              :detail-vaccine="vaccine"
              btn-title="Comparecimento"
              scheduled-type
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentVaccine = vaccine;
                  realizeVaccine = true;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = true;
                }
              "
            />
            <VaccineCard
              v-else-if="isWaiting(vaccine)"
              color="cyan"
              :detail-vaccine="vaccine"
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentVaccine = vaccine;
                  realizeVaccine = false;
                  register = true;
                }
              "
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = true;
                }
              "
            />
          </v-col>
        </v-row>
        <v-row
          v-if="activeFilter && fullfilledVaccines.length > 0"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Realizadas
            </p>
          </v-col>
          <v-col
            v-for="vaccine in fullfilledVaccines"
            :key="vaccine.id"
            cols="12"
          >
            <VaccineCard
              :detail-vaccine="vaccine"
              done-type
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = true;
                }
              "
              :action="
                () => {
                  setStatusToShowCombo(false);
                  currentVaccine = vaccine;
                  realizeVaccine = true;
                  register = true;
                }
              "
            />
          </v-col>
        </v-row>
        <v-row
          v-if="activeFilter && unrealizedVaccines.length > 0"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">
              Não Realizadas
            </p>
          </v-col>
          <v-col
            v-for="vaccine in unrealizedVaccines"
            :key="vaccine.id"
            cols="12"
          >
            <VaccineCard
              :detail-vaccine="vaccine"
              unrealized-type
              :detail="
                () => {
                  setStatusToShowCombo(false);
                  detailVaccine = true;
                  realizeVaccine = false;
                }
              "
            />
          </v-col>
        </v-row>
      </ProgressiveLoader>
    </v-layout>

    <VaccineRegister
      :success="
        () => {
          setStatusToShowCombo(false);
          registerSuccess = true;
        }
      "
      :close="
        () => {
          register = false;
          detailVaccine = false;
          realizeVaccine = false;
          currentVaccine = null;
          onCloseFromPushNotification();
          setStatusToShowCombo(true);
        }
      "
      v-if="register"
      :vaccine="currentVaccine"
      :clear-vaccine="() => (currentVaccine = null)"
      :realize-type="realizeVaccine"
      :detail-only="detailVaccine"
      :userId="user.userId"
      :userCpf="user.cpf"
      :dependentId="user.dependentId"
      :beneficiaries="beneficiaries"
      v-on:updateVaccineTotalizers="updateVaccineTotalizers"
      v-on:verifyIsRealized="verifyIsRealized"
    />

    <v-container v-if="registerSuccess">
      <v-card class="success-card" flat>
        <v-container class="pa-7">
          <img src="/img/vaccine/circle-vaccine.svg" class="success-img" />
          <p style="font-size: 36px" class="mt-16 pt-12 main-title">
            Vacina Registrada
          </p>

          <p v-if="!isRealized" style="font-size: 24px" class="pt-4 main-text">
            Não esqueça de informar se foi à vacina
          </p>
          <generic-btn
            style="width: 85%; bottom: 100px; position: absolute"
            :btn-props="{}"
            :on-click="
              () => {
                refreshRegister();
                registerSuccess = false;
                setStatusToShowCombo(true);
              }
            "
          >
            Ok
          </generic-btn>
        </v-container>
      </v-card>
    </v-container>

    <v-card
      v-if="!register && !registerSuccess"
      color="#2EC9D3"
      class="plus-icon"
      elevation="10"
      @click="
        () => {
          register = true;
          currentVaccine = null;
          realizeVaccine = false;
          setStatusToShowCombo(false);
        }
      "
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/plus-icon.svg" class="mt-4" />
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import Totalizer from "@/components/generic-totalizer";
import VaccineCard from "../../components/vaccine/VaccineCard";
import VaccineRegister from "../../components/vaccine/VaccineRegister";
import VaccineEventService from "../../services/healthcare/VaccineEventService";
import { mapMutations } from "vuex";
import moment from "moment";
import { vaccineTypes, vaccinesStatus } from "../../common/constants";
import ProgressiveLoader from "../../components/progressive-loader/index";

export default {
  name: "Vaccine",
  components: {
    backArrow,
    Totalizer,
    VaccineCard,
    VaccineRegister,
    ProgressiveLoader,
  },
  data() {
    return {
      busy: true,
      totals: [],
      activeFilter: false,
      lateVaccines: [],
      fullfilledVaccines: [],
      unrealizedVaccines: [],
      nextVaccines: [],
      waitingVaccines: [],
      moment,
      register: false,
      registerSuccess: false,
      currentVaccine: null,
      realizeVaccine: false,
      detailVaccine: false,
      isRealized: false,
      orderLoadingTypes: 1,
      orderLoadingTypesRealized: 1,
      pageLoad: -1,
      pageLoadRealizeds: -1,
      selectedLoadingType: "",
      selectedLoadingTypeRealized: "",
      user: {
        name: null,
        cpf: null,
        userId: null,
        dependentId: null,
      },
      beneficiaries: [],
      showComboBeneficiaries: true,
    };
  },
  watch: {
    register(newValue) {
      if (!newValue) {
        this.refreshRegister();
      }
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async refreshRegister() {
      this.nextVaccines = [];
      this.lateVaccines = [];
      this.fullfilledVaccines = [];
      this.unrealizedVaccines = [];
      this.waitingVaccines = [];
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = "";
      this.selectedLoadingTypeRealized = "";
      await this.getVaccines();
      this.updateVaccineTotalizers();
    },
    isWaiting(vaccine) {
      return vaccine.status === vaccinesStatus.waiting;
    },
    isScheduled(vaccine) {
      return vaccine.status === vaccinesStatus.scheduled;
    },
    async onClickTab() {
      this.activeFilter = !this.activeFilter;
      this.clearData();
      await this.getVaccines();
    },
    updateVaccineTotalizers() {
      this.getTotals();
    },
    verifyIsRealized() {
      this.isRealized = true;
    },
    getVaccineTypeText(type) {
      if (type === vaccineTypes.self) return "Iniciativa própria";

      if (type === vaccineTypes.suggestion) return "Protocolo Eu Protegido";

      if (type === vaccineTypes.refer) return "Indicação médica";
    },
    async getVaccines() {
      this.loading(true);
      this.busy = true;

      if (!this.activeFilter) {
        this.pageLoad++;
        if (this.orderLoadingTypes === 1) {
          this.selectedLoadingType = "ATRASADO_AGUARDANDO";
        } else if (this.orderLoadingTypes === 2) {
          this.selectedLoadingType = "ATRASADO_AGENDADO";
        } else if (this.orderLoadingTypes === 3) {
          this.selectedLoadingType = "PROXIMO_AGUARDANDO";
        } else {
          this.selectedLoadingType = "PROXIMO_AGENDADO";
        }

        let params = {
          eventStatus: this.selectedLoadingType,
          page: this.pageLoad,
          size: 4,
          sortOrder: "ASC",
        };

        if (this.orderLoadingTypes > 4) {
          this.loading(false);
          this.busy = false;
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._vaccineEventService
          .FindAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
          .then(async (response) => {
            if (response.data && response.data.content.length === 0) {
              this.pageLoad = -1;
              this.orderLoadingTypes++;
              await this.getVaccines();
            } else {
              if (this.selectedLoadingType === "ATRASADO_AGUARDANDO") {
                if (this.lateVaccines && this.lateVaccines.length > 0) {
                  this.lateVaccines = this.lateVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.lateVaccines = response.data.content;
                }
              } else if (this.selectedLoadingType === "ATRASADO_AGENDADO") {
                if (this.lateVaccines && this.lateVaccines.length > 0) {
                  this.lateVaccines = this.lateVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.lateVaccines = response.data.content;
                }
              } else if (this.selectedLoadingType === "PROXIMO_AGUARDANDO") {
                if (this.nextVaccines && this.nextVaccines.length > 0) {
                  this.nextVaccines = this.nextVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.nextVaccines = response.data.content;
                }
              } else {
                if (this.nextVaccines && this.nextVaccines.length > 0) {
                  this.nextVaccines = this.nextVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.nextVaccines = response.data.content;
                }
              }

              if (response.data && response.data.content.length === 1) {
                await this.getVaccines();
              }
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar as vacinas atrasadas e/ou próximas.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
            this.busy = false;
          });
      } else {
        this.pageLoadRealizeds++;
        if (this.orderLoadingTypesRealized === 1) {
          this.selectedLoadingTypeRealized = "REALIZADO";
        } else {
          this.selectedLoadingTypeRealized = "NAO_REALIZADO";
        }

        let params = {
          eventStatus: this.selectedLoadingTypeRealized,
          page: this.pageLoadRealizeds,
          size: 4,
          sortOrder: "DESC",
        };

        if (this.orderLoadingTypesRealized > 2) {
          this.loading(false);
          this.busy = false;
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._vaccineEventService
          .FindAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
          .then(async (response) => {
            if (response.data && response.data.content.length === 0) {
              this.pageLoadRealizeds = -1;
              this.orderLoadingTypesRealized++;
              await this.getVaccines();
            } else {
              if (this.selectedLoadingTypeRealized === "REALIZADO") {
                if (
                  this.fullfilledVaccines &&
                  this.fullfilledVaccines.length > 0
                ) {
                  this.fullfilledVaccines = this.fullfilledVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.fullfilledVaccines = response.data.content;
                }
              } else {
                if (
                  this.unrealizedVaccines &&
                  this.unrealizedVaccines.length > 0
                ) {
                  this.unrealizedVaccines = this.unrealizedVaccines.concat(
                    response.data.content
                  );
                } else {
                  this.unrealizedVaccines = response.data.content;
                }
              }

              if (response.data && response.data.content.length === 1) {
                await this.getVaccines();
              }
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar as vacinas realizadas.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
            this.busy = false;
          });
      }
    },
    getTotals() {
      this.loading(true);

      if (this.user.dependentId) {
        this.user.userId = null;
      }

      this._vaccineEventService
        .getTotalizers(this.user.userId, this.user.dependentId)
        .then(({ data }) => {
          this.totals = [
            {
              label: "Sugestões",
              value: data.totalSuggestionLastYear,
            },
            {
              label: "Próximas",
              value: data.totalSuggestionAndScheduledLastYear,
            },
            {
              label: "Histórico",
              value: data.totalRealizeds,
            },
          ];
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar totalizadores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async detailVaccineByPushNotification() {
      this.loading(true);
      if (this.$route.params && this.$route.params.id) {
        const id = this.$route.params.id;
        await this._vaccineEventService
          .findById(id)
          .then((response) => {
            if (response.data) {
              this.register = true;
              this.realizeVaccine = true;
              this.currentVaccine = response.data;
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar a vacina notificada.",
              type: "error",
            });
          })
          .finally(() => {
            this.loading(false);
          });
      }
    },
    onCloseFromPushNotification() {
      if (this.$route.params && this.$route.params.id) {
        this.clearData();
      }
    },
    clearData() {
      this.lateVaccines = [];
      this.fullfilledVaccines = [];
      this.unrealizedExams = [];
      this.nextVaccines = [];
      this.waitingVaccines = [];
      this.register = false;
      this.registerSuccess = false;
      this.currentVaccine = null;
      this.realizeVaccine = false;
      this.detailVaccine = false;
      this.isRealized = false;
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = "";
      this.selectedLoadingTypeRealized = "";
    },
    setStatusToShowCombo(status) {
      this.showComboBeneficiaries = status;
    },
    onClickComboBeneficiary() {
      this.refreshRegister();
    },
    initUserId() {
      if (this.$auth.user() && this.$auth.user().beneficiaries) {
        let user = this.$auth.user().beneficiaries[0];
        this.user = {cpf: user.personCPF, userId: user.userId, name: user.personName}
      } else if (this.$auth.user() && this.$auth.user().dependents) {
        let user = this.$auth.user().dependents[0];
        this.user = {cpf: user.personCPF, name: user.personName, userId: user.userId, dependentId: user.dependentId}
      } else {
        this.user.cpf = this.$auth.user() ? this.$auth.user().cpf : null;
      }
      this.beneficiaries = this.$auth.getBeneficiariesAndDependents();
    },
  },
  beforeCreate() {
    this._vaccineEventService = new VaccineEventService();
  },
  async mounted() {
    this.initUserId();
    await this.detailVaccineByPushNotification();
    this.getTotals();
    await this.getVaccines();
  },
};
</script>

<style >
/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.bg-effect {
  background: rgba(255, 255, 255, 0.7);
  mix-blend-mode: normal;
  padding: 10px;
}

.list-card {
  border-radius: 20px !important;
}

.option-list-card {
  width: 100% !important;
  height: 66px;
}

.plus-icon {
  border-radius: 50% !important;
  height: 60px;
  width: 60px;
  position: fixed !important;
  right: 30px;
  bottom: 30px;
}

.success-card {
  position: absolute;
  bottom: 0;
  left: 7.5%;
  height: 80vh;
  width: 85vw;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  z-index: 10;
}

@media (max-width: 379px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 24%;
    top: -100px;
  }
}

@media (min-width: 380px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 27.5%;
    top: -100px;
  }
}

@media (max-width: 389px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 4px !important;
  }
}

@media (min-width: 390px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 2px !important;
  }
}
</style>
