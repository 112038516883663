<template>
  <div :style="cssVars" class="container">
    <div class="progress">
      <div class="percent"/>
    </div>
    <div class="steps">
      <div
        v-for="n in doseAmount"
        :key="n"
        :class="n <= dose ? 'step selected' : 'step'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VaccineDoseProgress',
  props: {
    doseAmount: {
      required: true,
      type: Number
    },
    dose: {
      required: true,
      type: Number
    },
  },
  data() {
    return{
      percent: ''
    }
  },
  mounted() {
    this.percent = `${(100 / this.doseAmount) * this.dose}px`;
  },
  computed: {
    cssVars() {
      return {
        '--width-percent': this.percent,
      }
    },
  },
}
</script>

<style scoped>
.container {
  position: relative;
}
.steps {
  display: flex;
  justify-content: space-between;
  max-width: 100px;
  width: auto;
}
.step {
  width: 15px;
  height: 15px;
  background: #D8FCFE;
  border: 2px solid #D8FCFE;
  border-radius: 50%;
  transition: background 1s;
}
.step.selected {
  border: 2px solid #2EC9D3;
  background: #2EC9D3;
}

.progress {
  position: relative;
  max-width: 100px;
  width: auto;
  height: 100%;
  border-bottom: 4px solid #D8FCFE;
  z-index: -1;
  top: 120%;
  left: 0;
  transform: translate(0, 120%);
}
.percent {
  position: relative;
  max-width: 100px;
  width: var(--width-percent);
  height: 100%;
  border-bottom: 4px solid #2EC9D3;
  z-index: 1;
  top: 120%;
  left: 0;
  transform: translate(0, 120%);
}
</style>