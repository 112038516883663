export const quizResultsThemes = {
    covidGood: 'COVID_GOOD_RESULT',
    covidBad: 'COVID_BAD_RESULT',
    defaultGood: 'DEFAULT_GOOD_RESULT',
    defaultBad: 'DEFAULT_BAD_RESULT'
};

export const appointmentsStatus = {
    fullfilled: 'REALIZADO',
    unrealized: 'NAO_REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const appointmentTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}

export const regexValidations = {
    cep: /\d{5}-\d{3}$/,
}


export const vaccinesStatus = {
    fullfilled: 'REALIZADO',
    unrealized: 'NAO_REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const vaccineTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}

export const examStatus = {
    fullfilled: 'REALIZADO',
    unrealized: 'NAO_REALIZADO',
    scheduled: 'AGENDADO',
    waiting: 'AGUARDANDO_AGENDAMENTO'
}

export const examTypes = {
    suggestion: 'sugestao',
    self: 'proprio',
    refer: 'encaminhado'
}

export const regexEmail = /^(([^<>(){}!#$%^=+*\\[\]\\.,;:\s@"]+(\.[^<>(){}!#$%^=+*\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;