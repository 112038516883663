/* eslint-disable */
import HttpService from '../HttpService'

export default class ReminderService {
    constructor() {
        this._httpService = new HttpService(`healthcare/reminder`);
    }

    async getAll(){
        return await this._httpService.get('/');
    };

}
