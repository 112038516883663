<template>
  <span
    :style="cssVars"
    class="pulse"
  />
</template>

<script>
import { eu_protegido_theme } from '../../plugins/vuetify';

export default {
  name: 'PulseIcon',
  props: {
    color: {
      default: 'green',
      type: String,
    }
  },
  data() {
    return {
      bgColor: '',
      shadowColor: '',
    }
  },
  methods: {
    convertHex (color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, .3)`
    },
    getStatusColor() {
      this.bgColor = eu_protegido_theme[this.color];
      this.shadowColor = this.convertHex(this.bgColor);
    }
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.bgColor,
        '--shadow-color': this.shadowColor
      }
    }
  },
  beforeMount() {
    this.getStatusColor();
  }
}
</script>

<style scoped>
  .pulse {
    background: var(--bg-color);
    box-shadow: 0 0 0 var(--shadow-color);
    margin: 5px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 var(--shadow-color);
      transform: scale(0);
    }
    70% {
      -webkit-box-shadow: 0 0 0 4px var(--shadow-color);
      transform: scale(1.2);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 var(--shadow-color);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 var(--shadow-color);
      box-shadow: 0 0 0 0 var(--shadow-color);
      transform: scale(0);
    }
    70% {
      -moz-box-shadow: 0 0 0 4px var(--shadow-color);
      box-shadow: 0 0 0 4px var(--shadow-color);
      transform: scale(1.2);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 var(--shadow-color);
      box-shadow: 0 0 0 0 var(--shadow-color);
    }
  }
</style>