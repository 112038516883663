/* eslint-disable */
import HttpService from '../HttpService'

export default class AttachmentTypeService {
    constructor() {
        this._httpService = new HttpService(`healthcare`);
    }

    async findAllExamGroup(){
        return await this._httpService.get('/attachment_type/find_all_exam_group');
    };

    async findAllVaccineGroup(){
        return await this._httpService.get('/attachment_type/find_all_vaccine_group');
    };

    async findAllAppointmentGroup(){
        return await this._httpService.get('/attachment_type/find_all_appointment_group');
    };

}
