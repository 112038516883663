<template>
  <GmapMap
    v-if="geoLocation"
    :center="geoLocation"
    :zoom="17"
    map-type-id="roadmap"
    style="width: 100%; height: 300px"
  >
    <GmapMarker
      :position="geoLocation"
    />
  </GmapMap>
</template>

<script>
export default {
  name: 'google-map',
  props: {
    address: {
      type: String,
      default: ''
    },
    zipCode: {
      type: String,
      required: true
    },
    getState: {
      type: Function,
      required: false
    },
    getCity: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      geoLocation: {}
    }
  },
  methods: {
    getGeolocation() {
      let addressObj = {
        address_line_1: this.address,
        zip_code: this.zipCode,
        country: 'Brazil'
      }

      this.$geocoder.send(addressObj, response => {

        response.results[0].address_components.map(type => {
          type.types.map(t => {
            if (t === 'administrative_area_level_1') this.getState(type)
            if (t === 'administrative_area_level_2') this.getCity(type);
          })
        });

        this.geoLocation = response.results[0].geometry.location;
      });
    }
  },
  mounted() {
    this.getGeolocation();
  }
}
</script>

<style scoped>

</style>