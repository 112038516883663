<template>
  <v-card
    min-height="200"
    max-height="270"
    min-width="320"
    color="white"
    class="list-card scale-up-center"
    @click="() => { action(); detail(); }"
  >
    <v-container class="pa-6">
      <p style="font-weight: bolder" class="mb-0 generic-text">
        {{ specialityName }}
      </p>
      <v-chip class="mb-3" x-small color="#E9EBFD" style="color: var(--v-primary); font-weight: 600">
        {{ getVaccineTypeText(vaccine.vaccineType && vaccine.vaccineType.code) }}
      </v-chip>
      <p v-if="!doneType" style="font-size: 12px" class="main-strong-text">
        {{ getDateText(vaccine) }}
      </p>

      <p v-if="doneType" style="font-size: 12px" class="main-strong-text">
        Realizado em {{ moment(vaccine.realizationDate).format('DD/MM/YYYY') }}
      </p>

      <p style="font-size: 14px;" class="main-subtitle text-truncate mb-0">
        {{ labName }}
      <p v-if="vaccine && vaccine.vaccine && vaccine.vaccine.doseAmount && vaccine.vaccine.doseAmount === 1" style="font-size: 14px;" class="main-strong-text text-truncate">
        {{ vaccine.place || 'Local não informado' }}
      </p>
      <v-row align="center" v-if="vaccine && vaccine.vaccine && vaccine.vaccine.doseAmount && vaccine.vaccine.doseAmount > 1">
        <v-col class="pr-0" cols="4">
          <p
            class="main-strong-text mb-0 mt-1"
            style="font-size: 14px; color: var(--v-gray2)"
          >
            {{ vaccine.dose }} de {{ vaccine.vaccine.doseAmount }} doses
          </p>
        </v-col>
        <v-col class="pl-0" cols="8">
          <VaccineDoseProgress  :dose="vaccine.dose" :dose-amount="vaccine.vaccine.doseAmount" />
        </v-col>
      </v-row>
      <v-row v-if="!doneType && !unrealizedType">
        <v-col class="pr-1" :cols="!scheduledType ?  7 : 5">
          <v-row :class="scheduledType && 'mt-2'">
            <v-col class="pr-2" :cols="scheduledType ?  3 : 2">
              <pulseIcon :color="color" />
            </v-col>
            <v-col v-if="!scheduledType" class="pl-0" cols="10">
              <p style="font-size: 12px" class="main-strong-text mb-10">
                Aguardando <br/> agendamento sugerido
              </p>
            </v-col>
            <v-col v-if="scheduledType" class="pl-0" cols="9">
              <p  style="font-size: 12px" class="main-strong-text mb-10">
                Agendado
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-6 pl-0" :cols="scheduledType ?  7 : 5">
          <v-btn class="card-btn" @click="action" :color="color">
            {{ btnTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="start" v-if="doneType">
        <v-col cols="8">
          <v-row v-if="isSuggestion && !hasTrophySuggestion" class="mt-0 mb-2">
            <v-col cols="12">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Realizada fora do prazo
              </p>
            </v-col>
          </v-row>
          <v-row v-else-if="isSuggestion && hasTrophySuggestion">
            <v-col cols="3">
              <img
                  src="/img/icons/home/medal-badge.svg"
                  style="width: 40px; height: 40px"
              >
            </v-col>
            <v-col class="mt-2" cols="9">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Realizada no prazo
              </p>
            </v-col>
          </v-row>
          <v-row v-else-if="doneType && isSelfRefer" class="mt-0 mb-2">
            <v-col cols="12">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Realizada
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" v-if="unrealizedType">
        <v-col cols="8">
          <v-row v-if="isSuggestion" class="mt-0 mb-2">
            <v-col cols="12">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Não Realizada
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';
import pulseIcon from '@/components/pulse-icon';
import {vaccinesStatus, vaccineTypes} from '../../common/constants';
import VaccineDoseProgress from "./VaccineDoseProgress";

export default {
  name: 'VaccineCard',
  components: { pulseIcon, VaccineDoseProgress },
  props: {
    detailVaccine: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'blue'
    },
    doneType: {
      default: false,
      type: Boolean
    },
    unrealizedType: {
      default: false,
      type: Boolean
    },
    scheduledType: {
      default: false,
      type: Boolean
    },
    action: {
      type: Function,
      required: false
    },
    detail: {
      type: Function,
      required: false
    },
    btnTitle: {
      type: String,
      default: 'Registrar'
    }
  },
  data() {
    return {
      moment,
      vaccine: {},
    }
  },
  computed: {
    hasTrophySuggestion(){
      return ((this.doneType) && (this.isSuggestion) && (this.isRealizationUntilDate))
    },
    isSuggestion(){
      return this.vaccine && this.vaccine.vaccineType && this.vaccine.vaccineType.code
          && this.vaccine.vaccineType.code === vaccineTypes.suggestion
    },
    isSelfRefer(){
      return this.vaccine && this.vaccine.vaccineType && this.vaccine.vaccineType.code
          && (this.vaccine.vaccineType.code === vaccineTypes.self || this.vaccine.vaccineType.code === vaccineTypes.refer)
    },
    isRealizationUntilDate(){
      return ((this.vaccine && this.vaccine.realizationUntilDate && (new Date(this.vaccine.realizationDate) < new Date(this.vaccine.realizationUntilDate)))
      || (this.vaccine && !this.vaccine.realizationUntilDate))
    },
    specialityName() {
      if(this.vaccine.vaccine && this.vaccine.vaccine.name){
        if(this.vaccine.vaccine.name === 'Outros'){
          return `${this.vaccine.vaccine.name} - ${this.vaccine.comment}`
        }else{
          return this.vaccine.vaccine.name
        }
      }else{
        return 'Vacina não informada'
      }
    },
    labName() {
      if(this.vaccine && this.vaccine.laboratoryName) {
        return this.vaccine.laboratoryName !== ' ' ?
          this.vaccine.laboratoryName : 'Laboratório não informado'
      }else {
        return 'Laboratório não informado';
      }
    }
  },
  mounted() {
    this.vaccine = {...this.detailVaccine}
    if( !this.vaccine.vaccine || !this.vaccine.vaccine.doseAmount ){
      this.vaccine.vaccine = { doseAmount: 1 }
    }
  },
  methods: {
    getVaccineTypeText(type) {
      if(type === vaccineTypes.self)
        return 'Iniciativa própria'

      if(type === vaccineTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === vaccineTypes.refer)
        return 'Indicação médica'
    },
    getDateText(vaccine) {
      if(vaccine.status === vaccinesStatus.waiting)
        return `Realize até ${ moment(vaccine.realizationUntilDate).format('DD/MM/YYYY') }`

      if(vaccine.status === vaccinesStatus.scheduled)
        return `Data agendada ${ moment(vaccine.scheduledDate).format('DD/MM/YYYY') }`

      if(vaccine.status === vaccinesStatus.unrealized) {
        if(vaccine.realizationUntilDate) {
          return `Realize até ${ moment(vaccine.realizationUntilDate).format('DD/MM/YYYY') }`
        } else {
          return 'Data não agendada.'
        }
      }
    },
  }
}
</script>

<style scoped>
.card-btn {
  border-radius: 20px !important;
  color: white !important;
  text-transform: initial !important;
  font-weight: bolder !important;
}

.v-icon.v-icon  {
  color: var(--v-tertiary) !important;
  font-size: 15px !important;
  padding-left: 20px !important;
}

.list-card {
  border-radius: 20px !important;
  max-width: 90vw !important;
  width: auto;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.3s ease-in both;
  animation: scale-up-center 0.3s ease-in both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.option-list-card {
  width: 100% !important;
  height: 65px;
}
</style>
