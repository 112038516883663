import HttpService from '../HttpService'

export default class VaccineTypeService {
    constructor() {
        this._httpService = new HttpService('healthcare/vaccine_type');
    }

    async getVaccineTypes(){
        return await this._httpService.get('/');
    }

}
