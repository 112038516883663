<template>
  <v-container :style="containerHeight">
    <backArrow
      v-if="detailOnly"
      :action="
        () => {
          clearVaccine();
          close();
        }
      "
    />
    <v-row v-if="!detailOnly">
      <v-col class="pb-0 py-5" cols="10">
        <p v-if="!realizeType" class="main-strong-text mb-0">
          0{{ step }} de 05
        </p>
      </v-col>
      <v-col class="pb-0" cols="2">
        <img
          @click="
            () => {
              clearVaccine();
              close();
            }
          "
          src="/img/icons/common/close-icon.svg"
          style="height: 35px"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.medical && !vaccine">
      <v-col cols="12" class="pt-0 mt-0 pb-1 mb-1">
        <v-autocomplete
          label="Beneficiário selecionado"
          :items="beneficiaries"
          item-value="cpf"
          item-text="name"
          v-model="userCpf"
          color="primary"
          readonly
        >
        </v-autocomplete>
      </v-col>
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe a vacina e o tipo
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="specialityName"
          readonly
          placeholder="Vacina"
          @click="
            () => {
              form.comment = null;
              vaccineSpeciality = true;
            }
          "
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip
          :outlined="form.vaccineType.code !== vaccineTypes.self"
          color="primary"
          @click="() => getVaccineType(vaccineTypes.self)"
        >
          Iniciativa própria
        </v-chip>
        <v-chip
          class="ml-2"
          :outlined="form.vaccineType.code !== vaccineTypes.refer"
          color="primary"
          @click="() => getVaccineType(vaccineTypes.refer)"
        >
          Indicação médica
        </v-chip>
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Essa vacina já foi realizada"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.medical && vaccine">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Registrar Vacina</p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="form.vaccine.name"
          readonly
          placeholder="Vacina"
          @click="() => (vaccineSpeciality = true)"
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip color="primary">
          {{ getVaccineTypeText() }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Essa vacina já foi realizada"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.doctor && !form.laboratory.laboratoryName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Qual laboratório ou clínica?
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field placeholder="Buscar" v-model="searchLab">
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
            @click="() => filterLabHistory()"
          />
          <img
            v-if="searchLab"
            slot="append"
            src="/img/icons/common/close-icon.svg"
            style="width: 20px; height: 20px"
            @click="() => (searchLab = '')"
          />
        </v-text-field>
        <p
          v-if="laboratoryHistory.length > 0 && searchLab"
          class="main-strong-text mt-2"
          style="font-size: 16px"
        >
          Recentes
        </p>

        <v-row
          v-for="doctor in laboratoryHistory"
          :key="doctor.id"
          class="my-0 py-0"
          @click="
            () => {
              form.laboratory = {
                ...doctor,
                laboratoryCNPJ: doctor.laboratoryCnpj,
              };
              addLab = { ...doctor, laboratoryCNPJ: doctor.laboratoryCnpj };
            }
          "
        >
          <v-col
            v-if="doctor.laboratoryId && doctor.isMainLaboratory"
            class="pr-0 my-0 py-0"
            cols="1"
          >
            <img
              src="/img/icons/exame/building-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col v-else class="pr-0 my-0 py-0" cols="1">
            <img
              src="/img/icons/common/clock-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col class="my-0 py-0" cols="11">
            <p class="main-text" style="font-size: 16px">
              {{ doctor.laboratoryName ? doctor.laboratoryName : "" }}
            </p>
          </v-col>
        </v-row>

        <p
          v-if="laboratories.length > 0"
          class="main-strong-text"
          style="font-size: 16px"
        >
          Lista de laboratórios
        </p>

        <v-row
          v-if="searchLab"
          v-for="doctor in laboratories"
          :key="doctor.id"
          class="mt-4 mb-2"
          @click="
            () => {
              form.laboratory = {
                laboratoryName: doctor.name,
                laboratoryId: doctor.id,
                laboratoryPhone: doctor.phone,
                laboratoryCNPJ: doctor.cnpj_cpf,
              };
              addLab = form.laboratory;
            }
          "
        >
          <v-col class="pr-0" cols="1">
            <img
              src="/img/icons/exame/building-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col cols="11">
            <p class="main-text" style="font-size: 16px">
              {{ doctor.name ? doctor.name : "" }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="labNotFound()">
          <v-col cols="12">
            <p class="main-text" style="font-size: 16px">
              Laboratório não encontrado
            </p>
          </v-col>
          <v-col cols="12">
            <v-chip color="primary" @click="() => (newLaboratory = true)">
              adicionar laboratório
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="step === steps.doctor && form.laboratory.laboratoryName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Qual laboratório?</p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          readonly
          class="mb-2"
          label="Nome do laboratório"
          v-model="form.laboratory.laboratoryName"
        />
        <v-text-field
          readonly
          class="mb-2"
          v-mask="['##.###.###/####-##']"
          label="CNPJ"
          v-model="form.laboratory.laboratoryCNPJ"
        />
        <v-text-field
          readonly
          class="mb-2"
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="form.laboratory.laboratoryPhone"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.dose">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Qual dose você vai tomar?
        </p>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-if="form.vaccine.doseAmount > 1" v-model="form.dose">
          <v-radio
            class="mt-10"
            v-for="n in form.vaccine.doseAmount"
            :key="n"
            :label="`${n}ª dose`"
            :value="n"
          />
        </v-radio-group>

        <v-radio-group
          class="mt-10"
          mandatory
          v-if="form.vaccine.doseAmount === 1"
          v-model="form.dose"
        >
          <v-radio label="Dose única" :value="1" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="step === steps.date">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe a data, hora e o local do agendamento
        </p>
        <p
          class="main-strong-text mb-0 mt-16"
          style="font-size: 20px; color: var(--v-gray2)"
        >
          {{ form.dose }}ª dose
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-text-field
              @click="() => (addDate = true)"
              placeholder="Data da vacina"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => (addHour = true)"
              placeholder="Horário"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete
              @input="upateAddress"
              placeholder="Digite um local"
            />
          </v-col>
          <v-col
            v-if="
              !mapsCep && form.place && form.place.length > 0 && !editAddress
            "
            cols="12"
          >
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p class="generic-text" style="font-size: 16px">
                  {{ form.place }}
                </p>
              </v-col>
              <v-col
                v-if="detailOnly && !fullfilledStatus"
                @click="() => (editAddress = true)"
                cols="2"
              >
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p
              v-if="!vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}{{ local.city.long_name }} -
              {{ local.state.short_name }}
            </p>
            <p
              v-if="!vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="mb-0 main-text"
            >
              {{ this.local.cep }}
            </p>
            <p
              v-if="vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => (addReminder = true)"
                >
                  {{
                    form.reminder
                      ? form.reminder.description
                      : "Adicionar lembrete"
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row :class="detailOnly ? 'mt-8' : ''" v-if="step === steps.confirm">
      <v-col v-if="!realizeType" class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Confirme os dados para registrar
        </p>
      </v-col>

      <v-col v-if="realizeType" class="pt-0" cols="12">
        <p class="main-text mb-0">Vacina</p>
        <p class="main-title" style="font-size: 32px">
          {{ specialityName }}
        </p>
        <v-chip color="#F8F8FF" style="color: var(--v-primary)">
          {{ getVaccineTypeText() }}
        </v-chip>
        <p
          class="main-strong-text mb-0 mt-16"
          style="font-size: 20px; color: var(--v-gray2)"
        >
          {{ form.dose }}ª dose
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-if="!realizeType" cols="12">
            <v-text-field
              @click="() => (vaccineSpeciality = true)"
              :value="specialityName"
              readonly
              placeholder="Vacina"
            />
          </v-col>
          <v-col v-if="!realizeType" cols="12">
            <p class="main-strong-text" style="font-size: 16px">Tipo</p>
            <v-chip color="primary">
              {{ getVaccineTypeText() }}
            </v-chip>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              class="mb-2"
              label="Beneficiário selecionado"
              :items="beneficiaries"
              item-value="cpf"
              item-text="name"
              v-model="userCpf"
              color="primary"
              readonly
            >
            </v-autocomplete>
            <v-text-field
              class="mb-2"
              label="Nome do laboratório"
              v-model="form.laboratory.laboratoryName"
              :rules="realizeType && required"
              @click="() => (newLaboratory = true)"
            />
            <v-text-field
              readonly
              class="mb-2"
              label="CNPJ"
              v-mask="['##.###.###/####-##']"
              :rules="[...cnpjLen]"
              v-model="form.laboratory.laboratoryCNPJ"
              @click="() => (newLaboratory = true)"
            />
            <v-text-field
              class="mb-2"
              label="Telefone"
              :rules="[...phoneMinLen]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="form.laboratory.laboratoryPhone"
              @click="() => (newLaboratory = true)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => (addDate = true)"
              label="Data da vacina"
              placeholder="Data da vacina"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => (addHour = true)"
              label="Horário da vacina"
              placeholder="Horário da vacina"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => (addRealizationDate = true)"
              label="Data de realização"
              placeholder="Data de realização"
              readonly
              :value="formatRealizationDateInput"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => (addRealizationHour = true)"
              label="Horário de realização"
              placeholder="Horário de realização"
              readonly
              v-model="form.realizationHour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete
              @input="upateAddress"
              placeholder="Digite um local"
            />
          </v-col>
          <v-col
            v-if="
              !mapsCep && form.place && form.place.length > 0 && !editAddress
            "
            cols="12"
          >
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p class="generic-text" style="font-size: 16px">
                  {{ form.place }}
                </p>
              </v-col>
              <v-col
                v-if="detailOnly && !fullfilledStatus"
                @click="() => (editAddress = true)"
                cols="2"
              >
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p style="font-size: 20px; color: #3f3f3f" class="main-strong-text">
              Local
            </p>
            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p
              v-if="!vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}{{ local.city.long_name }} -
              {{ local.state.short_name }}
            </p>
            <p
              v-if="!vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="mb-0 main-text"
            >
              {{ this.local.cep }}
            </p>
            <p
              v-if="vaccine"
              style="font-size: 14px; color: #3f3f3f"
              class="main-text mb-1 mt-2"
            >
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => (addReminder = true)"
                >
                  {{
                    form.reminder
                      ? form.reminder.description
                      : "Adicionar lembrete"
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-row class="mb-2">
              <v-col v-if="!fullfilledStatus" cols="1">
                <img
                  src="/img/appointments/clip-icon.svg"
                  style="width: 25px; height: 25px"
                />
              </v-col>
              <v-col cols="11">
                <p
                  v-if="!fullfilledStatus"
                  class="generic-text"
                  style="font-size: 16px"
                  @click="() => (addAttach = true)"
                >
                  Adicionar Anexo
                </p>
              </v-col>
              <v-col cols="12">
                <v-chip
                  class="mb-16"
                  color="primary"
                  outlined
                  @click="() => (showAttach = true)"
                >
                  Ver anexos
                </v-chip>
                <v-row class="mb-16 pb-16"></v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="overlayOn && !fullfilledStatus" class="card-overlay" />
    <v-form ref="doctorForm">
      <v-card
        elevation="16"
        style="height: 80%; top: 25%"
        v-if="newLaboratory && !fullfilledStatus"
        class="medical-speciality"
      >
        <v-container class="pa-10 pt-6">
          <v-row class="mb-8">
            <v-col class="pt-0" cols="10">
              <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
                Adicionar laboratório
              </p>
            </v-col>
            <v-col class="pt-0" cols="2">
              <img
                @click="() => (newLaboratory = false)"
                src="/img/icons/common/close-icon.svg"
                style="height: 35px"
              />
            </v-col>
          </v-row>
          <v-text-field
            class="mb-2"
            label="Nome do laboratório"
            v-model="addLab.laboratoryName"
            :rules="required"
          />
          <v-text-field
            class="mb-2"
            label="CNPJ"
            v-mask="['##.###.###/####-##']"
            :rules="[...cnpjLen]"
            v-model="addLab.laboratoryCNPJ"
          />
          <v-text-field
            class="mb-2"
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="addLab.laboratoryPhone"
            :rules="[...phoneMinLen]"
          />

          <generic-btn
            style="width: 100%; margin-top: 10%"
            :btn-props="{}"
            :on-click="saveDoctor"
          >
            Salvar
          </generic-btn>
        </v-container>
      </v-card>
    </v-form>

    <v-card
      style="overflow: scroll"
      v-if="vaccineSpeciality && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-10">
        <v-text-field
          placeholder="Buscar"
          v-model="search"
          @change="() => filterSpecialities()"
        >
          <img
            slot="append"
            src="/img/icons/common/search-red.svg"
            style="width: 20px; height: 20px"
            @click="() => filterSpecialities()"
          />
        </v-text-field>
        <v-text-field
          v-if="form.vaccine.name == 'Outros'"
          placeholder="Adicione um comentário"
          v-model="form.comment"
          @change="() => (vaccineSpeciality = false)"
          ><img
            slot="append"
            src="/img/icons/common/plus-icon-pink.svg"
            style="width: 20px; height: 20px"
            @click="() => (vaccineSpeciality = false)"
          />
        </v-text-field>
        <v-card
          elevation="0"
          style="overflow: auto; background-color: white; height: 59vh"
        >
          <p
            v-for="speciality in specialities"
            :key="speciality.id"
            class="main-text mt-4 mb-8"
            style="font-size: 16px"
            @click="
              speciality.name != 'Outros'
                ? closeSpeciality(speciality)
                : (form.vaccine = speciality)
            "
          >
            {{ speciality && speciality.name }}
          </p>
        </v-card>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      v-if="addDate && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data da vacina
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addDate = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-date-picker
          flat
          full-width
          v-model="form.date"
          color="#706CE0"
          no-title
        />
        <generic-btn
          style="width: 100%; margin-top: -5%"
          :btn-props="{}"
          :on-click="() => (addDate = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 90%; top: 15%"
      v-if="addHour && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addHour = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-row class="d-flex justify-center text-h1 primary--text"
            >{{ timePicker.hour }}:{{ timePicker.minutes }}</v-row
          >
          <v-row class="d-flex justify-center mt-10">
            <v-col cols="5">
              <v-select
                v-model="timePicker.hour"
                :items="hourOptions"
                item-text="text"
                item-value="value"
                label="Hora(s)"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="timePicker.minutes"
                :items="minutesOptions"
                item-text="text"
                item-value="value"
                label="Minuto(s)"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>

        <generic-btn
          style="width: 100%; margin-top: 0%"
          :btn-props="{}"
          :on-click="() => (addHour = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      v-if="addRealizationDate && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addRealizationDate = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-date-picker
          flat
          full-width
          v-model="form.realizationDate"
          color="#706CE0"
          no-title
          :max="moment().format('YYYY-MM-DD')"
        />

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addRealizationDate = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 90%; top: 15%"
      v-if="addRealizationHour && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addRealizationHour = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-row class="mb-10">
          <v-row class="d-flex justify-center text-h1 primary--text"
            >{{ realizationTimePicker.hour }}:{{
              realizationTimePicker.minutes
            }}</v-row
          >
          <v-row class="d-flex justify-center mt-10">
            <v-col cols="5">
              <v-select
                v-model="realizationTimePicker.hour"
                :items="hourOptions"
                item-text="text"
                item-value="value"
                label="Hora(s)"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="realizationTimePicker.minutes"
                :items="minutesOptions"
                item-text="text"
                item-value="value"
                label="Minuto(s)"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addRealizationHour = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      :style="{
        height: form.attachments.length > 0 ? '90%' : '25%',
        top: form.attachments.length > 0 ? '15%' : '80%',
      }"
      v-if="showAttach"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (showAttach = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-list v-if="form.attachments.length > 0">
          <v-list-item
            v-for="attach in form.attachments"
            :key="attach.fileName"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">{{
                attach.attachmentType ? attach.attachmentType.name : "Arquivo"
              }}</v-list-item-title>
              <v-list-item-subtitle style="font-size: 20px">
                <a
                  style="color: gray"
                  :href="`data:application/octet-stream;base64,${
                    attach.fileContent ? attach.fileContent : ''
                  }`"
                  :download="getAttachmentDownload(attach)"
                  @click="onClickNativeApp(attach)"
                >
                  {{ attach.fileName ? attach.fileName : "" }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <img
                @click="deleteAttach(attach)"
                src="/img/icons/common/close-icon.svg"
                style="height: 30px"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <p v-else class="generic-text text-center">Sem anexos adicionados</p>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      style="height: 85%; top: 20%"
      v-if="addReminder && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Alarme
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addReminder = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="
              form.reminder && form.reminder.id === alert.id
                ? { backgroundColor: '#F8F8FF' }
                : {}
            "
            v-for="alert in reminders"
            :key="alert.id"
            class="pt-1"
            @click="() => (form.reminder = alert)"
          >
            <v-col class="pb-0" cols="10">
              <p class="main-text" style="font-size: 16px">
                {{ alert.description }}
              </p>
            </v-col>

            <v-col
              v-if="form.reminder && form.reminder.id === alert.id"
              class="pb-0 pt-3"
              cols="2"
            >
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => (addReminder = false)"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>

    <label v-show="false" id="fileUpload" ref="fileUpload" for="file">
      <input type="file" ref="file" @change="updateFiles" v-show="false" />
    </label>

    <v-card
      elevation="16"
      style="height: 85%; top: 20%"
      v-if="addAttach && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addAttach = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="
              currentAttachType === type ? { backgroundColor: '#F8F8FF' } : {}
            "
            v-for="type in attachTypes"
            :key="type.id"
            class="pt-1"
            @click="() => (currentAttachType = type)"
          >
            <v-col class="pb-0" cols="10">
              <p class="main-text" style="font-size: 16px">
                {{ type.name }}
              </p>
            </v-col>

            <v-col v-if="currentAttachType === type" class="pb-0 pt-3" cols="2">
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="triggerClick"
        >
          Adicionar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      v-if="addLocal && !fullfilledStatus"
      class="medical-speciality"
    >
      <v-container class="pa-10 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Local
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img
              @click="() => (addLocal = false)"
              src="/img/icons/common/close-icon.svg"
              style="height: 35px"
            />
          </v-col>
        </v-row>

        <v-text-field
          label="CEP"
          v-model="local.cep"
          :rules="local.cep && cepValidation"
          v-mask="'#####-###'"
        />

        <v-text-field label="Bairro" v-model="local.neighbour" />

        <v-text-field label="Rua" v-model="local.address" />

        <v-text-field
          label="Nº"
          :rules="local.number && numberOnly"
          v-model="local.number"
        />

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{ disabled: disableLocalForm }"
          :on-click="saveAddress"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-layout v-if="!fullfilledStatus" justify-center align-end>
      <v-card style="height: 15%" elevation="16" class="step-btn">
        <generic-btn
          style="width: 90%; margin-top: 7%"
          class="ml-5"
          :btn-props="{
            disabled:
              disableSave ||
              !this.form.realizationDate ||
              !this.form.realizationHour,
          }"
          :on-click="() => realizeVaccine()"
          v-if="step === steps.confirm && realizeType && !detailOnly"
        >
          Comparecer
        </generic-btn>
        <generic-btn
          style="width: 90%; margin-top: 8%"
          class="ml-5"
          :btn-props="{
            disabled:
              disableSave ||
              !this.form.realizationDate ||
              !this.form.realizationHour,
          }"
          :on-click="() => updateVaccine()"
          v-if="step === steps.confirm && detailOnly"
        >
          Alterar
        </generic-btn>
        <generic-btn
          style="width: 90%; margin-top: 7%"
          class="ml-5"
          :btn-props="{
            disabled: !this.form.vaccine.name || !this.form.vaccineType.code,
          }"
          :on-click="() => step++"
          v-if="step === steps.medical && !realizeType"
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; margin-left: 8%; width: 40%"
          :btn-props="{ outlined: true }"
          :on-click="() => step--"
          v-if="step !== steps.medical && !realizeType"
        >
          Voltar
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableNext }"
          :on-click="() => step++"
          v-if="
            step !== steps.medical && step !== steps.confirm && !realizeType
          "
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableSave }"
          :on-click="() => saveVaccine()"
          v-if="step === steps.confirm && !realizeType"
        >
          Registrar
        </generic-btn>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import {
  vaccineTypes,
  regexValidations,
  vaccinesStatus,
} from "../../common/constants";
import VaccineService from "../../services/healthcare/VaccineService";
import VaccineEventService from "../../services/healthcare/VaccineEventService";
import ReminderService from "../../services/healthcare/ReminderService";
import VaccineTypeService from "../../services/healthcare/VaccineTypeService";
import AttachmentTypeService from "../../services/healthcare/AttachmentTypeService";
import AccountService from "../../services/contract/AccountService";
import { mapMutations } from "vuex";
import moment from "moment";
import googleMap from "../../components/google-maps/google-map";
import backArrow from "../../components/covid/back-arrow";
import GoogleMapAutocomplete from "../../components/google-maps/google-map-autocomplete";
import Vue from "vue";
import {Capacitor} from "@capacitor/core";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {Share} from "@capacitor/share";

export default {
  name: "VaccineRegister",
  components: {
    googleMap,
    backArrow,
    GoogleMapAutocomplete,
  },
  props: {
    vaccine: {
      type: Object,
      required: false,
    },
    clearVaccine: {
      type: Function,
      required: true,
    },
    close: {
      required: true,
      type: Function,
    },
    success: {
      required: false,
      type: Function,
    },
    realizeType: {
      type: Boolean,
      default: false,
    },
    detailOnly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: false,
    },
    userCpf: {
      type: String,
      required: false,
    },
    dependentId: {
      type: Number,
      required: false,
    },
    beneficiaries: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
      vaccineTypes,
      editAddress: false,
      currentAttachType: null,
      newLaboratory: false,
      addDate: false,
      addRealizationDate: false,
      addHour: false,
      addRealizationHour: false,
      addLocal: false,
      addReminder: false,
      addAttach: false,
      showAttach: false,
      vaccineSpeciality: false,
      step: 1,
      steps: {
        medical: 1,
        doctor: 2,
        dose: 3,
        date: 4,
        confirm: 5,
      },
      addLab: {
        laboratoryName: null,
        laboratoryPhone: null,
        laboratoryId: null,
        laboratoryCNPJ: null,
      },
      local: {
        cep: null,
        neighbour: "",
        address: "",
        number: "",
        city: "",
        state: "",
      },
      mapsCep: null,
      form: {
        fullfilled: false,
        vaccineType: { code: null },
        vaccine: { name: null },
        laboratory: { laboratoryName: null },
        date: null,
        realizationDate: null,
        hour: null,
        realizationHour: null,
        place: null,
        reminder: null,
        attachments: [],
        userId: null,
        dependentId: null,
        dose: null,
        comment: null,
      },
      allSpecialities: [],
      specialities: [],
      allLaboratoryHistory: [],
      laboratoryHistory: [],
      allLaboratories: [],
      laboratories: [],
      reminders: [],
      types: [],
      attachTypes: [],
      search: "",
      searchLab: "",
      timerId: undefined,
      timePicker: {
        hour: "00",
        minutes: "00",
      },
      realizationTimePicker: {
        hour: "00",
        minutes: "00",
      },
      hourOptions: [
        { text: "00", value: "00" },
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
      ],
      minutesOptions: [
        { text: "00", value: "00" },
        { text: "05", value: "05" },
        { text: "10", value: "10" },
        { text: "15", value: "15" },
        { text: "20", value: "20" },
        { text: "25", value: "25" },
        { text: "30", value: "30" },
        { text: "35", value: "35" },
        { text: "40", value: "40" },
        { text: "45", value: "45" },
        { text: "50", value: "50" },
        { text: "55", value: "55" },
      ],
      numberOnly: [
        (value) => {
          const pattern = /^\d*$/;
          return value && (pattern.test(value) || "Precisa ser um número");
        },
      ],
      cepValidation: [
        (value) => {
          const pattern = regexValidations.cep;
          return value && (pattern.test(value) || "Precisa ser um CEP válido");
        },
      ],
      required: [(value) => !!value || "Campo obrigatório"],
      phoneMinLen: [
        (value) =>
          !value ||
          (value.length >= 14 || value.length <= 1
            ? true
            : "Mínimo 8 números + DDD"),
      ],
      cnpjLen: [
        (value) => !value || value.length === 18 || "Precisa ter 14 números",
      ],
    };
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    upateAddress(data) {
      const { code, neighborhood, region, city, log, number } = data;

      this.local.cep = code;
      this.local.neighbour = neighborhood;
      this.local.address = log;
      this.local.city = city;
      this.local.state = region;
      this.local.number = number;

      // Enable Form to finish address register
      this.addLocal = true;
    },
    updateVaccineTotalizers() {
      this.$emit("updateVaccineTotalizers");
    },
    verifyIsRealized() {
      this.$emit("verifyIsRealized");
    },
    saveDoctor() {
      let valid = this.$refs.doctorForm.validate();

      if (valid) {
        Vue.set(this.form, "laboratory", this.addLab);
        this.newLaboratory = false;
      }
    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () =>
          resolve({
            name: file.name,
            body: fileReader.result,
            size: file.size,
          });

        fileReader.readAsDataURL(file);
      });
    },
    async updateFiles(files) {
      const file = await Promise.resolve(
        this.readFileContent(files.target.files[0])
      );

      this.form.attachments.push({
        fileName: file.name.substring(0, file.name.lastIndexOf(".")) || "",
        contentType:
          file.name.substring(file.name.lastIndexOf("."), file.length) || "",
        fileContent: file.body.split(",")[1],
        attachmentType: this.currentAttachType,
      });
    },
    triggerClick() {
      this.$refs.file.click();
      this.addAttach = false;
    },
    deleteAttach(attach) {
      let remove = this.form.attachments.findIndex((file) => attach === file);
      this.form.attachments.splice(remove, 1);
    },
    getVaccineType(type) {
      this.types.filter((t) => {
        if (t.code === type) this.form.vaccineType = t;
      });
    },
    getVaccineTypeText() {
      let type = this.form.vaccineType.code;

      if (type === vaccineTypes.self) return "Iniciativa própria";

      if (type === vaccineTypes.suggestion) return "Protocolo Eu Protegido";

      if (type === vaccineTypes.refer) return "Indicação médica";
    },
    labNotFound() {
      return (
        this.laboratories.length === 0 &&
        this.laboratoryHistory.length === 0 &&
        this.searchLab
      );
    },
    closeSpeciality(speciality) {
      this.form.vaccine = speciality;
      this.vaccineSpeciality = false;
    },
    getCity(city) {
      this.local.city = city;
    },
    getState(state) {
      this.local.state = state;
    },
    saveAddress() {
      let { address, number, cep, neighbour } = this.local;

      if (cep) {
        this.mapsCep = cep;

        if (neighbour) this.form.place = `${address}, ${number}, ${neighbour}/`;
        else this.form.place = `${address}, ${number}/`;
      } else {
        if (neighbour) this.form.place = `${address}, ${number}, ${neighbour}`;
        else this.form.place = `${address}, ${number}`;
      }

      this.addLocal = false;
      this.editAddress = false;
    },
    async getSpecialities() {
      await this._vaccineService
        .getVaccineAllSpecialities()
        .then((res) => {
          this.specialities = res.data;
          this.allSpecialities = this.specialities;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar as vacinas!",
            type: "error",
          })
        );
    },
    getReminders() {
      this.loading(true);

      this._reminderService
        .getAll()
        .then((res) => {
          this.reminders = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar alertas!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getTypes() {
      this.loading(true);

      this._vaccineTypeService
        .getVaccineTypes()
        .then((res) => {
          this.types = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de vacina!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getAttachTypes() {
      this.loading(true);

      this._attachmentTypeService
        .findAllVaccineGroup()
        .then((res) => {
          this.attachTypes = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de anexos!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async getLabHistory() {
      await this._vaccineEventService
        .getLabHistory(this.userId, this.dependentId)
        .then((res) => {
          this.laboratoryHistory = res.data;
          if (this.laboratoryHistory && this.laboratoryHistory.length) {
            this.laboratoryHistory = this.laboratoryHistory.map((lab) => ({
              ...lab,
              isMainLaboratory: lab.laboratoryId ? true : false,
            }));
          }
          this.allLaboratoryHistory = this.laboratoryHistory;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar histórico dos laboratórios!",
            type: "error",
          })
        );

      await this._accountService
        .findAllMainLaboratories()
        .then((res) => {
          let laboratories = res.data;
          for (let laboratory of laboratories) {
            let mainLaboratory = {};
            mainLaboratory.laboratoryCNPJ = laboratory.cnpj_cpf
              ? laboratory.cnpj_cpf
              : "";
            mainLaboratory.laboratoryId = laboratory.id ? laboratory.id : "";
            mainLaboratory.laboratoryName = laboratory.name
              ? laboratory.name
              : laboratory.legalName
              ? laboratory.legalName
              : "";
            mainLaboratory.laboratoryPhone = laboratory.phone
              ? laboratory.phone
              : "";
            this.laboratoryHistory.push(mainLaboratory);
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar histórico dos laboratórios!",
            type: "error",
          })
        );
    },
    getLabsByName() {
      this.loading(true);

      this._accountService
        .findAllLaboratoryByName(this.searchLab)
        .then((res) => {
          this.laboratories = res.data;
          this.allLaboratories = this.laboratories;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar os doutores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    filterSpecialities() {
      if (this.search) {
        let filtered = this.allSpecialities.filter((article) => {
          let query = this.search.toLowerCase();
          let name = article.name && article.name.toLowerCase();

          return name && name.indexOf(query) > -1;
        });

        this.specialities = filtered;
      } else {
        this.specialities = this.allSpecialities;
      }
    },
    fetchEntriesDebounce() {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.filterLabHistory();
      }, 2000);
    },
    filterLabHistory() {
      if (this.searchLab) {
        let filtered = this.allLaboratoryHistory.filter((article) => {
          let query = this.searchLab.toLowerCase();
          let name =
            article.laboratoryName && article.laboratoryName.toLowerCase();

          return name && name.indexOf(query) > -1;
        });

        this.laboratoryHistory = filtered;
        this.getLabsByName();
      } else {
        this.laboratoryHistory = this.allLaboratoryHistory;
      }
    },
    saveVaccine() {
      this.loading(true);
      this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
      this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

      let date = moment(`${this.form.date} ${this.form.hour}`).format(
        "YYYY-MM-DD[T]HH:mm:SS[.000Z]"
      );
      let parseRealizationDate = null;
      let address = `${this.form.place}`;
      let placeCep =
        this.vaccine &&
        this.vaccine.place &&
        this.vaccine.place.match(regexValidations.cep);

      if (this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:SS[.000Z]");
      }

      if (!placeCep)
        if (this.local.city && this.local.state && this.local.cep)
          address = `${this.form.place} ${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

      let holderReminder = false
      let userId = this.form.userId
      if(userId == null){
        userId = this.beneficiaries.find(beneficiary => beneficiary.userId != null && beneficiary.dependentId == null).userId
        holderReminder = true
      }

      let data = {
        ...this.form,
        ...this.form.laboratory,
        userId: userId,
        scheduledDate: date,
        holderReminder: holderReminder,
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        realizationUntilDate:
          this.vaccine && this.vaccine.realizationUntilDate
            ? this.vaccine.realizationUntilDate
            : null,
        place: address,
      };

      this._vaccineEventService
        .createVaccine(data)
        .then(() => {
          this.loading(false);
          this.close();
          this.success();
          this.updateVaccineTotalizers();
          if (this.form.fullfilled === true) {
            this.verifyIsRealized();
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar a vacina!",
            type: "error",
          })
        )
        .finally(() => {
          this.clearVaccine();
          this.loading(false);
        });
    },
    updateVaccine() {
      this.loading(true);
      this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
      this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

      let date = moment(`${this.form.date} ${this.form.hour}`).format(
        "YYYY-MM-DD[T]HH:mm:SS[.000Z]"
      );
      let address = `${this.form.place}`;
      let parseRealizationDate = null;
      let placeCep =
        this.vaccine &&
        this.vaccine.place &&
        this.vaccine.place.match(regexValidations.cep);

      if (this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:SS[.000Z]");
      }

      if (!placeCep)
        if (this.local.city && this.local.state && this.local.cep)
          address = `${this.form.place}${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

      let holderReminder = false
      let userId = this.form.userId
      if(userId == null){
        userId = this.beneficiaries.find(beneficiary => beneficiary.userId != null && beneficiary.dependentId == null).userId
        holderReminder = true
      }

      let data = {
        ...this.form,
        ...this.form.laboratory,
        userId: userId,
        scheduledDate: date,
        holderReminder: holderReminder,
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        realizationUntilDate:
          this.vaccine && this.vaccine.realizationUntilDate
            ? this.vaccine.realizationUntilDate
            : null,
        place: address,
      };

      this._vaccineEventService
        .updateVaccine(this.vaccine.id, data)
        .then(() => {
          this.close();
          this.success();
          this.updateVaccineTotalizers();
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar a vacina!",
            type: "error",
          })
        )
        .finally(() => {
          this.clearVaccine();
          this.loading(false);
        });
    },
    realizeVaccine() {
      if (this.doctorValid) {
        this.loading(true);
        this.form.hour = `${this.timePicker.hour}:${this.timePicker.minutes}`;
        this.form.realizationHour = `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;

        let parseRealizationDate = moment(
          `${this.form.realizationDate} ${this.form.realizationHour}`
        ).format("YYYY-MM-DD[T]HH:mm:SS[.000Z]");
        let address = `${this.form.place}`;
        let placeCep =
          this.vaccine &&
          this.vaccine.place &&
          this.vaccine.place.match(regexValidations.cep);

        if (!placeCep)
          if (this.local.city && this.local.state && this.local.cep)
            address = `${this.form.place}${this.local.city.long_name} - ${this.local.state.short_name} / ${this.local.cep}`;

        let data = {
          ...this.form.laboratory,
          id: this.form.id,
          userId: this.form.userId,
          dependentId: this.form.dependentId,
          realizationDate: parseRealizationDate,
          place: address,
          vaccine: this.form.vaccine,
          scheduledDate: this.vaccine.scheduledDate,
          realizationUntilDate:
            this.vaccine && this.vaccine.realizationUntilDate
              ? this.vaccine.realizationUntilDate
              : null,
          comment: this.vaccine.comment,
          vaccineType: { id: this.vaccine.vaccineType.id },
          attachments: this.form.attachments,
        };

        this._vaccineEventService
          .realizeVaccine(data)
          .then(() => {
            this.loading(false);
            this.close();
            this.updateVaccineTotalizers();
          })
          .catch(() =>
            this.showmsg({
              text: "Erro ao registrar vacina! Por favor preencha todos os campos.",
              type: "error",
            })
          )
          .finally(() => {
            this.clearVaccine();
            this.loading(false);
          });
      } else {
        this.newLaboratory = true;
      }
    },
    getAttachmentType(attach) {
      return attach.contentType ? attach.contentType.replaceAll(".", "") : "";
    },
    getAttachmentDownload(attach) {
      return attach.fileName && attach.contentType
        ? attach.fileName + attach.contentType
        : "";
    },
    async onClickNativeApp(attach) {
      if(Capacitor.isNativePlatform()) {
        this.loading(true);
        const file = attach.fileName && attach.contentType ? attach.fileName + attach.contentType : null;
        if(file) {
          await Filesystem.writeFile({
            path: file,
            data: 'data:application/octet-stream;base64,' + attach.fileContent,
            directory: Directory.Documents,
          }).then((result) => {

            Share.share({
              title: 'Vacina',
              text: 'Anexo de Vacina',
              url: result.uri,
              dialogTitle: 'Eu Protegido',
            });

            setTimeout(() => {
              this.loading(false);
            }, 1500)

          }).catch(() => {
            this.loading(false);
          });
        }
      }
    }
  },
  watch: {
    "form.fullfilled"(newValue) {
      if (newValue) {
        this.form.realizationDate = moment().format("YYYY-MM-DD");
        this.form.realizationHour = moment().format("HH:mm");
      } else {
        this.form.realizationDate = null;
        this.form.realizationHour = null;
      }
    },
    search() {
      this.filterSpecialities();
    },
    searchLab(value) {
      this.laboratoryHistory = [];
      this.allLaboratories = [];
      this.laboratories = [];
      this.addLab.laboratoryName = value;
      this.fetchEntriesDebounce();
    },
    addHour(val) {
      this.form.hour = val
        ? ""
        : `${this.timePicker.hour}:${this.timePicker.minutes}`;
    },
    addRealizationHour(val) {
      this.form.realizationHour = val
        ? ""
        : `${this.realizationTimePicker.hour}:${this.realizationTimePicker.minutes}`;
    },
  },
  computed: {
    fullfilledStatus() {
      if (this.vaccine) {
        if (this.vaccine.status === vaccinesStatus.fullfilled) return true;
        else return false;
      } else return false;
    },
    specialityName() {
      if (this.form.vaccine && this.form.vaccine.name === "Outros") {
        return `${this.form.vaccine.name}  ${
          this.form.comment == null ? "" : "- " + this.form.comment
        }`;
      } else {
        return this.form.vaccine.name;
      }
    },
    overlayOn() {
      return (
        this.newLaboratory ||
        this.addLocal ||
        this.addDate ||
        this.addHour ||
        this.addReminder ||
        this.addAttach ||
        this.showAttach ||
        this.vaccineSpeciality
      );
    },
    containerHeight() {
      if (this.step === this.steps.date) return { height: "90vh" };

      if (this.step === this.steps.confirm) return { height: "100vh" };
      else return { height: "90vh" };
    },
    disableNext() {
      if (this.step === this.steps.doctor)
        return !this.form.laboratory.laboratoryName;

      if (this.step === this.steps.dose) return !this.form.dose;

      if (this.step === this.steps.date)
        return !this.form.date || !this.form.hour || !this.form.place;
      else return false;
    },
    disableLocalForm() {
      return (
        !this.local.address ||
        !this.local.number ||
        (this.local.cep && !this.local.cep.match(regexValidations.cep))
      );
    },
    formatDateInput() {
      return this.form.date ? moment(this.form.date).format("DD/MM/YYYY") : "";
    },
    formatRealizationDateInput() {
      return this.form.realizationDate
        ? moment(this.form.realizationDate).format("DD/MM/YYYY")
        : "";
    },
    doctorValid() {
      return this.form.laboratory.laboratoryId || !!this.addLab.laboratoryName;
    },
    disableSave() {
      return (
        !this.form.vaccine ||
        !this.form.laboratory.laboratoryName ||
        !this.form.date ||
        !this.form.hour ||
        !this.form.place
      );
    },
  },
  beforeCreate() {
    this._accountService = new AccountService();
    this._attachmentTypeService = new AttachmentTypeService();
    this._vaccineService = new VaccineService();
    this._vaccineEventService = new VaccineEventService();
    this._vaccineTypeService = new VaccineTypeService();
    this._reminderService = new ReminderService();
  },
  async mounted() {
    if (window) {
      window.scrollTo(0, 0);
    }

    this.loading(true);
    await this.getSpecialities();
    await this.getLabHistory();
    this.loading(true);

    this.getReminders();
    this.getTypes();
    this.getAttachTypes();

    this.form.userId = this.userId;
    this.form.dependentId = this.dependentId;

    if (this.realizeType) {
      this.step = this.steps.confirm;
    }

    if (this.vaccine) {
      let cep =
        this.vaccine.place && this.vaccine.place.match(regexValidations.cep);

      if (cep && cep[0]) {
        this.mapsCep = cep[0];
        this.local.cep = cep[0];
      }

      this.addLab = {
        laboratoryName: this.vaccine.laboratoryName,
        laboratoryId: this.vaccine.laboratoryId,
        laboratoryPhone: this.vaccine.laboratoryPhone,
        laboratoryCNPJ: this.vaccine.laboratoryCNPJ,
      };

      let actualDate = null;
      let actualHour = null;
      if (!this.detailOnly) {
        actualDate = this.vaccine.realizationDate
          ? moment(this.vaccine.realizationDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        actualHour = this.vaccine.realizationDate
          ? moment(this.vaccine.realizationDate).format("HH:mm")
          : moment().format("HH:mm");
      } else {
        actualDate = this.vaccine.realizationDate
          ? moment(this.vaccine.realizationDate).format("YYYY-MM-DD")
          : null;
        actualHour = this.vaccine.realizationDate
          ? moment(this.vaccine.realizationDate).format("HH:mm")
          : null;
      }

      this.form = {
        ...this.vaccine,
        realizationDate: actualDate,
        realizationHour: actualHour,
        fullfilled: false,
        laboratory: {
          laboratoryName: this.vaccine.laboratoryName,
          laboratoryId: this.vaccine.laboratoryId,
          laboratoryPhone: this.vaccine.laboratoryPhone,
          laboratoryCNPJ: this.vaccine.laboratoryCNPJ,
        },
        date: this.vaccine.scheduledDate
          ? moment(this.vaccine.scheduledDate).format("YYYY-MM-DD")
          : null,
        hour: this.vaccine.scheduledDate
          ? moment(this.vaccine.scheduledDate).format("HH:mm")
          : null,
        vaccineType: this.vaccine.vaccineType
          ? this.vaccine.vaccineType
          : { code: null },
        vaccine: this.vaccine.vaccine
          ? this.vaccine.vaccine
          : { name: null, doseAmount: 1 },
        doseAmount: this.vaccine.doseAmount ? this.vaccine.doseAmount : 1,
      };
    }
  },
};
</script>

<style>
.step-btn {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 110px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.medical-speciality {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 75%;
  position: fixed;
  top: 30%;
  left: 0;
  bottom: 0;
  z-index: 20;
  -webkit-animation: medical-speciality 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: medical-speciality 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.card-overlay {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  background: rgba(0, 0, 0, 0.6);
}

.scroll-hidden {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

@-webkit-keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
