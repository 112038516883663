/* eslint-disable */
import HttpService from '../HttpService';
export default class AccountService {
  constructor() {
    this._httpService = new HttpService(`contract`);
  }

  async findAllCarrierByBeneficiaryAndHasReimbursementApp(){
      return await this._httpService.get(`/account/findAllCarrierByBeneficiaryAndHasReimbursementApp`);
  }

  async findAllCarrierByBeneficiary(){
      return await this._httpService.get(`/account/findAllCarrierByBeneficiary`);
  }

  async findAllCarrierByDependent(){
    return await this._httpService.get(`/account/findAllCarrierByDependent`);
  }

  async findAllCarrierByBeneficiaryAndHasAccreditedNetworkApp(){
      return await this._httpService.get(`/account/findAllCarrierByBeneficiaryAndHasAccreditedNetworkApp`);
  }

  async findAllLaboratoryByName(accountName){
    return await this._httpService.get(`/account/findAllLaboratoryByName/${accountName}`);
  }

  async findAllMainLaboratories(){
    return await this._httpService.get('/account/findAllMainLaboratories');
  }

  async getDoctors(name){
    return await this._httpService.get(`/account/findAllHealthcareProfessionalByName/${ name }`);
  }

 };
